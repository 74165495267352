module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Playfair Display","file":"https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700"},{"name":"Jost","file":"https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600&display"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
